export function validatorPhone(value,e){
  if(e.required || value!=''){
    return /^1[3456789]\d{9}$/.test(value)
  }else{
    return true
  }
}
export function validatorIDcard(value,e){
  if(e.required || value!=''){
    return /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)
  }else{
    return true
  }
}
export function validatorEmail(value,e){
  if(e.required || value!=''){
    return /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
  }else{
    return true
  }
}


const validateToNextPassword=(rule, value, callback) => {
  if (!value) {
    if(rule.required){
      callback(new Error(rule.emptymsg))
    }else{
      callback()
    }
  } else {
    if (rule.confirm != value ) {
      callback(new Error('两次密码输入一致'))
    } else {
      callback()
    }
  }
}
//整数
const validatorNumber = (rule, value, callback) => {
  if (!value) {
    if(rule.required){
      callback(new Error(rule.emptymsg))
    }else{
      callback()
    }
  }else{
    if (!/^\d+$/.test(value)) {
      callback(new Error('请输入数字类型'))
    } else {
      callback()
    }
  }
}

//数字可以是小数
const validatorSNumber = (rule, value, callback) => {
  if (!value) {
    if(rule.required){
      callback(new Error(rule.emptymsg))
    }else{
      callback()
    }
  }else{
    if (!/^(([0-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,3})))$/.test(value)) {
      callback(new Error('请输入数字类型'))
    } else {
      callback()
    }
  }
}
export {
  validateToNextPassword,
  validatorNumber,
  validatorSNumber
}
