<template>
  <div class="container">
    <div class="flexTopRight">
      <a @click="()=>{
          contentHtml = detail.rule
          contentHtmlModal = true
        }" class="link">活动规则</a>
      <a @click="goService(detail.kefu_url)">客服</a>
    </div>

    <div class="header">
      <img :src="oss+'2023030802.png'" width="100%"/>

      <van-count-down millisecond :time="time">
        <template #default="timeData">
          <span class="block">{{ timeData.minutes }}</span>
          <span class="colon">:</span>
          <span class="block">{{ timeData.seconds }}</span>
          <span class="colon">:</span>
          <span class="block">{{moment(timeData.milliseconds).format("SS")}}</span>
        </template>
      </van-count-down>

    </div>
    <div class="text">
      <van-icon name="good-job-o" size="20" />
      超火爆，该最高抽奖品10000+人想要
    </div>
    <div class=""><img :src="oss+'2023030803.jpg'" width="100%"/></div>
    <div class="t1">填写手机号参与抽奖</div>

    <div class="main">
      <van-form @submit="onsubmit" :show-error="false" scroll-to-error label-width="50">

        <van-field label="姓名" v-model="json.name" type="text" placeholder="请输入姓名" :rules="[{ required: true,message: '请输入姓名'}]"/>
        <van-field label="手机号" v-model="json.phone" type="tel" placeholder="请输入手机号" :rules="[{ required: true,validator:validatorPhone,message: '请输入正确的手机号码'}]" ref="phone"/>
        <van-field label="地址" v-model="json.address" type="text" placeholder="请输入地址" :rules="[{ required: true,message: '请输入正确的手机号码'}]"/>
        <div class="paybtn"><van-button round block native-type="submit" :loading="loading">立即领取</van-button></div>
      </van-form>
      <div class="tips">
        <van-checkbox v-model="isread" icon-size="16px" checked-color="#667999">提交已视为阅读并同意</van-checkbox>
        <div><a @click="()=>{
          contentHtml = detail.xieyi
          contentHtmlModal = true
        }">个人信息授权与保护声明</a></div>
      </div>
    </div>

    <van-popup v-if="contentHtmlModal" v-model="contentHtmlModal" :overlay-style="{backgroundColor:'rgba(0,0,0,.5)'}" :close-on-click-overlay="false" class="contentPop">
      <div class="html" v-html="contentHtml"></div>
      <div class="close"><van-button type="primary" round color="#f60" style="width:60%" @click="()=>{contentHtmlModal = false}">确定</van-button></div>
    </van-popup>

  </div>

</template>
<script>
  import {validatorPhone} from '@/utils/validata'
  import moment from 'moment'
  import {GetPayConfig,FinishOrder} from './services/services'
  export default {
    components: {},
    data () {
      return {
        time:1000*60*10,
        detail:{},
        type:'',
        json:{
          name:'',
          order_sn:'',
          phone:'',
          address:'',
        },
        contentHtmlModal:false,
        contentHtml:'',
        isread:true,
        loading:false
      }
    },
    created(){
      const {type,order_sn} = this.$route.query
      this.type = type
      this.json.order_sn = order_sn
    },
    watch:{
      'json.phone':{
        handler:function(value){
          console.log(value.length)
          if(value.length == 11){
            if(!/^1[3456789]\d{9}$/.test(value)) {
              this.$toast('请输入正确的手机号码')
              this.$refs.phone.focus()
            }else{
              this.$refs.phone.blur()
            }
          }
        },
        deep:true
      }
    },
    computed:{
    },
    mounted(){
      this.findpayConfig()
    },
    methods:{
      validatorPhone,
      moment,
      goService(url){
        location.href=url
      },
      findpayConfig(){
        GetPayConfig({type:this.type}).then(result => {
          if(result.data.code==1){
            this.detail = result.data.data
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      },
      onsubmit(){
        if(!this.isread){
          this.$toast('请阅读个人信息授权与保护声明')
          return false
        }
        this.loading = true
        FinishOrder(this.json).then(result => {
          this.loading = false
          if(result.data.code==1){
            this.$router.push({path:'/render/success'})
          }else{
            this.$toast.fail(result.data.msg)
          }
        })

      }
    }
  }

</script>
<style lang="less" scoped>
  .container{
    .flexTopRight{position:fixed;right:0px;top:10px;z-index:99;text-align:right;width:30px;text-align:center;
      a{width:100%;background:#fe4c40;border:1px solid #fa7a6d;color:#fff;padding:10px 0px;border-radius:4px 0px 0px 4px;display:inline-block;writing-mode: vertical-rl;text-align:center;display:flex;flex-direction:column;justify-content:center;margin:4px 0px;}
    }
    .header{position:relative;
      img{width:100%;display:block;}

      .van-count-down{position:absolute;right:30px;bottom:28px;color:#fff;display:flex;align-items:center;
        .block{background:#fff;color:#d93639;padding:4px 0px;border-radius:4px;font-size:18px;width:30px;display:inline-block;text-align:center;}
        .colon{padding:0px 4px;font-weight:700;}
      }
    }
    .text{background:#fdfbee;display:flex;align-items:center;justify-content:center;color:#c56a3b;height:40px;}
    .t1{color:#ed3823;font-size:22px;text-align:center;height:60px;line-height:60px;font-weight:700;}
    .main{padding:0px 20px;
      .from_item{
        .van-cell{}
      }
      .paybtn{margin-top:20px;
        .van-button{background:#e62d33;color:#fff;border:none;height:44px;line-height:44px;
          .van-button__text{font-size:18px;}
        }
      }
      .tips{margin-top:20px;font-size:12px;display:flex;align-items:center;
        a{color:#667999;text-decoration:underline;}
        /deep/.van-checkbox__label{font-size:12px;color:#999;}
      }
    }


  }

</style>
